import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_KEY_LANGUAGE } from './constants';
import { AppLanguage } from '../providers/language.provider';
import { SupportedLanguage } from '../services/language.service';

export interface LanguageSettings {
  selected: SupportedLanguage;
  availableLanguages: Array<AppLanguage>;
}

@Injectable({ providedIn: 'root' })
export class LanguageState {
  constructor(private preferenceService: PreferencesService) {}

  language$!: BehaviorSubject<LanguageSettings>;
  private readonly STORAGE_KEY = STORAGE_KEY_LANGUAGE;

  // Selected language is initialized in the language.service
  async initialize(): Promise<void> {
    let language = await this.get();

    if (!language) {
      language = {
        availableLanguages: [],
        selected: SupportedLanguage.English,
      };
      await this.set(language);
    }

    this.language$ = new BehaviorSubject<LanguageSettings>(language);

    return Promise.resolve();
  }

  async set(settings: Partial<LanguageSettings>): Promise<void> {
    const updatedOptions: LanguageSettings = {
      ...this.language$?.value,
      ...settings,
    };

    if (this.language$) {
      this.language$.next(updatedOptions);
    } else {
      this.language$ = new BehaviorSubject<LanguageSettings>(updatedOptions);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }

  private async get(): Promise<LanguageSettings | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }
}
