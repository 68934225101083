<div (click)="toggleOptions()" [class.disabled]="disabled" [class.options-displayed]="optionsDisplayed" class="select-box">
  <div class="placeholder" data-cy="language-selected">{{ selectedValueLabel | translate }}</div>
  <img [class.options-displayed]="optionsDisplayed" alt="" src="assets/icon/down-arrow-blue.svg" />

  @if (optionsDisplayed) {
    <div class="select-options" [@fadeInOut] [style.width]="selectBoxWidth + 'px'">
      @for (option of options; track $index) {
        <div class="select-option" (click)="selectOption(option.value)">
          {{ option.label | translate }}
        </div>
      }
    </div>
  }
</div>
