<div class="main-container" [class.mobile]="appService.isMobile$ | async">
  <div class="title">{{ 'Enter_header' | translate }}</div>

  @if (appService.isMobile$ | async) {
    <img class="logo" src="assets/logo/wr-logo.svg" alt="" />
  } @else {
    <div class="subtitle">{{ 'PWA_Enter_body_chooseLanguage' | translate }}</div>
  }

  <app-language-selection
    (cancel)="closeModal()"
    (languageChanged)="languageChanged($event)"
    [allowCancel]="false"
    [isLoading]="isLoading"
    [preselectValue]="false"></app-language-selection>
</div>
