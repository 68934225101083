import { Injectable } from '@angular/core';
import { EventAttributesMapper, EventType } from './analytics.model';

@Injectable({
  providedIn: 'root',
})
export class GA4Service {
  pushPageView(url: string): void {
    window.dataLayer.push({
      event: 'page_view',
      page_path: url,
    });
  }

  pushCustomEvent<T extends EventType>(eventName: T, url: string, eventAttributes: EventAttributesMapper[T]): void {
    const eventData = {
      event: eventName,
      page_path: url,
      ...eventAttributes,
    };

    if (window && window.dataLayer) {
      window.dataLayer.push(eventData);
    } else {
      console.error('GTM dataLayer is not initialized.');
    }
  }
}
