import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_KEY_PROFILE } from './constants';
import { Profile } from '../models/profile.model';

interface UserProfiles {
  currentProfile: Profile | null;
  profiles: Array<Profile>;
}

@Injectable({ providedIn: 'root' })
export class ProfileState {
  constructor(private preferenceService: PreferencesService) {}

  profiles$!: BehaviorSubject<Array<Profile>>;
  currentProfile$!: BehaviorSubject<Profile | null>;

  private readonly STORAGE_KEY = STORAGE_KEY_PROFILE;

  async initialize(): Promise<void> {
    const storedProfile = await this.get();

    if (storedProfile) {
      this.currentProfile$ = new BehaviorSubject(storedProfile.currentProfile);
      this.profiles$ = new BehaviorSubject(storedProfile.profiles);
    } else {
      void this.set({ currentProfile: null, profiles: [] });
    }

    return;
  }

  async set(settings: Partial<UserProfiles>): Promise<void> {
    const updatedOptions: UserProfiles = <UserProfiles>{
      profiles: settings.profiles,
      currentProfile: settings.currentProfile,
    };

    if (this.profiles$ && this.currentProfile$) {
      this.profiles$.next(updatedOptions.profiles);
      this.currentProfile$.next(updatedOptions.currentProfile);
    } else {
      this.profiles$ = new BehaviorSubject(updatedOptions.profiles);
      this.currentProfile$ = new BehaviorSubject(updatedOptions.currentProfile);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }

  private async get(): Promise<UserProfiles | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }

  async reset(): Promise<void> {
    await this.preferenceService.remove(this.STORAGE_KEY);
    await this.initialize();

    this.currentProfile$.next(null);
    this.profiles$.next([]);
  }
}
