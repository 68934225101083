import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fadeInOut } from '../../utils/animations';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';

export interface SelectOption<T> {
  readonly value: T;
  readonly label: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-select-popover',
  templateUrl: './select-popover.component.html',
  styleUrl: './select-popover.component.scss',
  animations: [fadeInOut],
  standalone: true,
  imports: [TranslateModule],
})
export class SelectPopoverComponent<T> implements OnInit {
  @ViewChild('selectBox') selectBox!: ElementRef<HTMLElement>;

  @Input() disabled = false;
  @Input() selectedValue?: T;
  @Input() control?: FormControl<T>;
  @Input() placeholder?: string;
  @Input({ required: true }) options!: SelectOption<T>[];
  @Output() valueChange = new EventEmitter<T>();

  protected optionsDisplayed = false;

  get selectBoxWidth(): number {
    return this.selectBox?.nativeElement?.offsetWidth;
  }

  protected get selectedValueLabel(): string {
    let value: T | undefined;
    if (this.control) {
      value = this.control.value;
    } else {
      value = this.selectedValue;
    }
    return this.options.find(option => option.value === value)?.label ?? this.placeholder ?? '';
  }

  ngOnInit(): void {
    if (!this.selectedValue && !this.placeholder && this.options.length > 0) {
      this.selectedValue = this.options[0].value;
    }
  }

  protected selectOption(value: T): void {
    this.selectedValue = value;
    this.control?.setValue(value);
    this.valueChange.emit(value);
  }

  protected toggleOptions(): void {
    if (!this.disabled) {
      this.optionsDisplayed = !this.optionsDisplayed;
    }
  }
}
