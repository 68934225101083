import { Injectable } from '@angular/core';
import { EventsRequest } from '@aws-sdk/client-pinpoint';
import pkg from '../../../../package.json';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserLocationState } from '../../store/user-location.state';
import { HttpClientService } from '../http-client.service';
import { UserState, UserType } from '../../store/user.state';
import { StoredProject } from '../../models/projects.model';
import { DefaultProjectState } from '../../store/default-project.state';
import { EventAttributesMapper, EventType } from './analytics.model';
import { ProfileState } from '../../store/profile.state';
import { Profile } from '../../models/profile.model';
import { LanguageState } from '../../store/language.state';
import { GA4Service } from './ga4.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  constructor(
    private http: HttpClient,
    private httpClientService: HttpClientService,
    private ga4Service: GA4Service,
    private router: Router,
    private userState: UserState,
    private profileState: ProfileState,
    private userLocationState: UserLocationState,
    private defaultProjectState: DefaultProjectState,
    private languageState: LanguageState,
  ) {}

  sendEvent<T extends EventType>(eventType: T, attributes: EventAttributesMapper[T]): void {
    // sendEvent(eventType: AnalyticsEventType, attributes?: { screenName: string }) {
    const defaultProject: StoredProject = this.defaultProjectState.defaultProject$.value;
    const userLocation = this.userLocationState.userLocation$.value;
    const url = this.router.url.split('?')[0]; // Ignoring QueryParams to avoid 200 characters limit on Pinpoint attribute
    const queryParams = this.router.url.split('?')[1]; // Getting Query Params
    const user = this.userState.user$.value;
    const profile = this.profileState.currentProfile$.value;
    const clientId = this.httpClientService.getClientCodeAndToken().clientCode;
    const language = this.languageState.language$.value;

    const globalAttributes = {
      // Org & Project
      organization_code: defaultProject.organizationCode,
      program_id: defaultProject.programId,
      project_id: defaultProject.id,

      // Application
      app_client_id: clientId,
      version_code: pkg.version, // Same from package.json
      title: pkg.name, // Same from package.json
      ui_language: language.selected,

      // User
      user_id: user.id ? user.id : user.id,
      guest_user_id: user.userType === UserType.GUEST ? user.id : user.guestCredentials?.id,

      // Profile
      profile_id: profile?.id ? profile.id : '',
      profile_gender: profile?.gender ? profile.gender : '',
      profile_min_age: profile?.minAge ? profile.minAge : '',
      profile_max_age: profile?.maxAge ? profile.maxAge : '',
      profile_earned_points: profile?.totalEarnedPoints ? profile.totalEarnedPoints : '',
      profile_finished_books: profile?.totalFinishedBooks ? profile.totalFinishedBooks : '',
      profile_weekly_reading_goal_books: profile?.weeklyReadingGoalBooks ? profile.weeklyReadingGoalBooks : '',
      profile_type: this.profileType(profile),
      is_family_time: profile?.isFamilyProfile ? 'true' : 'false',

      // Location (IP API)
      country: this.userLocationState.userLocation$.value.countryCode,
      administrative_area_1: userLocation.regionName,
      administrative_area_2: userLocation.district,
      city: userLocation.city,
      postal_code: userLocation.zip,

      // Device & Web
      user_agent: window.navigator.userAgent,
      url, // Current path
      queryParams,
      is_opera_mini_type: 'false', // set to True in this project
      event_datetime: new Date().toISOString(), // date time in UTC ISO standard ISO 8601
    };

    // eslint-disable-next-line
    const eventProperties: any = {
      AppPackageName: clientId,
      AppTitle: pkg.name,
      AppVersionCode: pkg.version,
      // The important
      EventType: eventType,
      // Adding custom attributes for each event and converting property names from camel to underscore
      Attributes: this.formatObjectForPinpoint({ ...globalAttributes, ...attributes }),
    };

    const pinPointObject: EventsRequest = {
      BatchItem: {
        '<keys>': {
          Endpoint: {
            Location: {
              // City: "STRING_VALUE",
              Country: userLocation.countryCode,
              // Latitude: Number("double"),
              // Longitude: Number("double"),
              // PostalCode: "STRING_VALUE",
              // Region: "STRING_VALUE",
            },
            User: {
              UserAttributes: {
                '<keys>': [''],
              },
              UserId: user.id,
            },
          },
          Events: {
            [eventType]: eventProperties,
          },
        },
      },
    };

    this.http.post<void>(`${environment.usersBaseUrl}/events`, pinPointObject).subscribe({});

    // Send page view events to google Analytics
    if (eventType === 'inScreen') {
      this.ga4Service.pushPageView(url);
    } else {
      this.ga4Service.pushCustomEvent(eventType, url, attributes);
    }
  }

  profileType(profile: Profile | null): 'family_time' | 'user' | 'guest' {
    if (profile?.id) {
      return profile.isFamilyProfile ? 'family_time' : 'user';
    } else {
      return 'guest';
    }
  }

  // eslint-disable-next-line
  formatObjectForPinpoint(obj: any): any {
    const newObj: Record<string, string> = {};
    let numberOfAttributes = 0;

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && numberOfAttributes < 40) {
        const underscoreKey = key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase(); // Convert camelCase to lowercase with underscores.
        newObj[underscoreKey] = obj[key]; // Assign the value to the new key.

        // Make sure that the value is not empty
        if (newObj[underscoreKey] === '') {
          delete newObj[underscoreKey];
          continue;
        }

        // Also convert numbers into strings as Pinpoint doesn't like numbers
        if (typeof newObj[underscoreKey] === 'number') {
          newObj[underscoreKey] = newObj[underscoreKey].toString();
        }

        // Make sure the value is not longer than 200 chars
        if (newObj[underscoreKey]?.length > 200) {
          newObj[underscoreKey] = newObj[underscoreKey].slice(0, 200);
        }

        numberOfAttributes++;
      }
    }

    return newObj;
  }
}
