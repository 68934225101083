import { PreferencesService } from '../services/preferences.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_KEY_FLAGS } from './constants';

export interface Flags {
  profileOnboardingCompleted: boolean;
  moyaProfileOnboardingCompleted: boolean;
  languageSelectionModalPrompted: boolean;
}

@Injectable({ providedIn: 'root' })
export class FlagsState {
  constructor(private preferenceService: PreferencesService) {}

  flags$!: BehaviorSubject<Flags>;
  private readonly STORAGE_KEY = STORAGE_KEY_FLAGS;

  readonly flagsInitialValue = {
    profileOnboardingCompleted: false,
    languageSelectionModalPrompted: false,
    moyaProfileOnboardingCompleted: false,
  };

  async initialize(): Promise<void> {
    const storedFlags = await this.get();

    if (storedFlags) {
      this.flags$ = new BehaviorSubject<Flags>(storedFlags);
    } else {
      void this.set(this.flagsInitialValue);
    }

    return;
  }

  async set(settings: Partial<Flags>): Promise<void> {
    const updatedOptions: Flags = {
      ...this.flags$?.value,
      ...settings,
    };

    if (this.flags$) {
      this.flags$.next(updatedOptions);
    } else {
      this.flags$ = new BehaviorSubject<Flags>(updatedOptions);
    }

    await this.preferenceService.set(this.STORAGE_KEY, JSON.stringify(updatedOptions));
  }

  private async get(): Promise<Flags | null> {
    return JSON.parse(<string>(await this.preferenceService.get(this.STORAGE_KEY)).value);
  }
}
