import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { LanguageProvider, Translations } from '../providers/language.provider';
import { Device } from '@capacitor/device';
import { ModalController } from '@ionic/angular/standalone';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageState } from '../store/language.state';
import { UserState, UserType } from '../store/user.state';
import { Translatable } from '../models/translation.model';
import { FlagsState } from '../store/flags.state';
import { LanguageSelectionModalPage } from '../modals/language-selection/language-selection-modal.page';

export enum SupportedLanguage {
  English = 'eng',
  Hindi = 'hin',
  Spanish = 'spa',
}

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(
    private languageProvider: LanguageProvider,
    private translateService: TranslateService,
    private languageState: LanguageState,
    private flagsState: FlagsState,
    private userState: UserState,
    private modalController: ModalController,
    private router: Router,
  ) {}

  initialized$ = new BehaviorSubject(false);

  async initialize(): Promise<void> {
    await this.initializeTranslations();

    return Promise.resolve();
  }

  private async initializeTranslations(): Promise<void> {
    const selectedLanguage = this.languageState.language$.value.selected;

    if (this.flagsState.flags$.value.languageSelectionModalPrompted) {
      await this.applyTranslations(selectedLanguage);
      this.initialized$.next(true);
      return;
    }

    // First access, we check the device language & try to match with app languages
    const twoCharsDeviceLanguageCode = (await Device.getLanguageCode()).value;
    let deviceLanguageCode: SupportedLanguage | null = null;
    switch (twoCharsDeviceLanguageCode) {
      case 'en':
        deviceLanguageCode = SupportedLanguage.English;
        break;
      case 'hi':
        deviceLanguageCode = SupportedLanguage.Hindi;
        break;
      case 'es':
        deviceLanguageCode = SupportedLanguage.Spanish;
    }

    const availableLanguages = await firstValueFrom(this.languageProvider.getLanguages());
    await this.languageState.set({ availableLanguages });

    // If language is not set (1st time the app is loaded, we check if the device match one of the available language
    if (!this.flagsState.flags$.value.languageSelectionModalPrompted) {
      const languageToApply = availableLanguages.find(availableLanguage => availableLanguage.code === deviceLanguageCode);

      if (languageToApply?.code) {
        await this.languageState.set({ selected: selectedLanguage });

        // We don't need to prompt the user to select a language, it's been detected by the browser
        void this.flagsState.set({ languageSelectionModalPrompted: true });
      }
    } else {
      await this.languageState.set({ availableLanguages });
    }

    // If no language has been found, we load english by default
    await this.applyTranslations(selectedLanguage);

    this.initialized$.next(true);
  }

  async setLanguage(selectedLanguage: SupportedLanguage): Promise<void> {
    // Backend is not configured to allow guest token for this host for now. We need to store the language in the storage.
    if (this.userState.user$.value.userType !== UserType.GUEST) {
      await firstValueFrom(this.languageProvider.setLanguage(selectedLanguage));
    }

    await this.languageState.set({ selected: selectedLanguage });

    await this.applyTranslations(selectedLanguage);
  }

  private async applyTranslations(selectedLanguage: string): Promise<void> {
    const translations: Translations = await firstValueFrom(this.languageProvider.getTranslations(selectedLanguage));
    this.translateService.setTranslation(selectedLanguage, translations);
    this.translateService.setDefaultLang(selectedLanguage);
  }

  translateBackendCopy(translatable: Translatable): string {
    const userSelectedLanguageTranslation =
      translatable.translations &&
      translatable.translations.find(translations => translations.language === this.languageState.language$.value.selected)?.value;

    return userSelectedLanguageTranslation || translatable.default;
  }

  // We display this modal only the first time the user access the app & if the device language does not match
  // With one of the language available in the app.
  async optionalShowLanguagePopup(): Promise<void> {
    if (!this.flagsState.flags$.value.languageSelectionModalPrompted) {
      await this.router.navigate(['/home']);

      const languageSelectionModal = await this.modalController.create({
        canDismiss: (_, role?: string) => new Promise<boolean>(resolve => resolve(role === 'close')),
        component: LanguageSelectionModalPage,
        animated: false,
        cssClass: 'wr-modal language-selection fullscreen',
      });
      await languageSelectionModal.present();
    }
  }
}
