import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { AppService } from '../../services/app.service';
import { LanguageService, SupportedLanguage } from '../../services/language.service';
import { FlagsState } from '../../store/flags.state';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AnalyticsEventType, ModalViewEvents } from '../../services/analytics/analytics.model';
import { LanguageSelectionComponent } from '../../components/language-selection/language-selection.component';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-language-selection-modal',
  templateUrl: './language-selection-modal.page.html',
  styleUrls: ['./language-selection-modal.page.scss'],
  standalone: true,
  imports: [LanguageSelectionComponent, AsyncPipe, TranslateModule],
})
export class LanguageSelectionModalPage {
  constructor(
    public appService: AppService,
    private modalController: ModalController,
    private languageService: LanguageService,
    private flagsState: FlagsState,
    private analyticsService: AnalyticsService,
  ) {}

  isLoading = false;

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.LanguageSelectionModal });
  }

  async languageChanged(language: SupportedLanguage): Promise<void> {
    this.isLoading = true;
    await this.languageService.setLanguage(language);
    void this.flagsState.set({ languageSelectionModalPrompted: true });
    this.isLoading = false;

    this.closeModal();
  }

  closeModal(): void {
    void this.modalController.dismiss(undefined, 'close');
  }
}
