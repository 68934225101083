import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageState } from '../../store/language.state';
import { ToastService, ToastType } from '../../services/toast.service';
import { AppService } from '../../services/app.service';
import { SupportedLanguage } from '../../services/language.service';
import { FlagsState } from '../../store/flags.state';
import { SelectPopoverComponent } from '../select-popover/select-popover.component';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
  standalone: true,
  imports: [SelectPopoverComponent, AsyncPipe, TranslateModule, ButtonComponent],
})
export class LanguageSelectionComponent implements OnInit {
  @Input({ required: true }) allowCancel!: boolean;
  @Input({ required: true }) isLoading = false;
  @Input() preselectValue = true;
  @Output() languageChanged = new EventEmitter<SupportedLanguage>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    public appService: AppService,
    private languageState: LanguageState,
    private flagsState: FlagsState,
    private translateService: TranslateService,
    private toastService: ToastService,
  ) {}

  selectedLanguage: SupportedLanguage | null = null;

  ngOnInit(): void {
    this.selectedLanguage = this.preselectValue ? this.languageState.language$.value.selected : null;
  }

  languageChange(language: SupportedLanguage | null): void {
    this.selectedLanguage = language as SupportedLanguage;
  }

  get languageOptions(): Array<{ value: SupportedLanguage; label: string }> {
    return this.languageState.language$.value.availableLanguages.map(language => ({ value: language.code, label: language.name }));
  }

  async applyLanguage(): Promise<void> {
    if (this.selectedLanguage === this.languageState.language$.value.selected && this.flagsState.flags$.value.languageSelectionModalPrompted) {
      void this.toastService.present({
        message: this.translateService.instant('PWA_toast_appLanguage_changeToUpdate'),
        type: ToastType.Negative,
        displayClose: true,
      });
      return;
    }

    this.isLoading = true;

    this.languageChanged.emit(this.selectedLanguage as SupportedLanguage);
  }
}
