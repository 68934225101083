import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UrlBuilder } from '../utils/url-builder';
import { SupportedLanguage } from '../services/language.service';

export interface Translations {
  [key: string]: string;
}

export interface Language {
  name: string;
  id?: number;
  code: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  direction: string;
}

export interface AppLanguage extends Language {
  code: SupportedLanguage;
}

enum ApiEndpoint {
  GetLanguages = '/languages',
  SetLanguage = '/settings',
  GetTranslations = '/translations',
}

@Injectable({ providedIn: 'root' })
export class LanguageProvider {
  constructor(private http: HttpClient) {}

  getLanguages(): Observable<AppLanguage[]> {
    return this.http.get<AppLanguage[]>(environment.settingsBaseUrl + ApiEndpoint.GetLanguages);
  }

  setLanguage(language: string): Observable<void> {
    return this.http.patch<void>(environment.usersBaseUrl + ApiEndpoint.SetLanguage, { language });
  }

  getTranslations(lang: string): Observable<Translations> {
    const url = new UrlBuilder(ApiEndpoint.GetTranslations)
      .setQueryParams({
        lang,
        format: 'keyValue',
      })
      .getUrl();

    return this.http.get<Translations>(environment.settingsBaseUrl + url);
  }
}
