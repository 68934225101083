import { animate, style, transition, trigger } from '@angular/animations';

const fadeOutTransition = transition(':leave', [style({ opacity: 1 }), animate('100ms ease-out', style({ opacity: 0 }))]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('100ms ease-in', style({ opacity: 1 }))]),
  fadeOutTransition,
]);

export const fadeIn = trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate('100ms ease-in', style({ opacity: 1 }))])]);

export const fadeOut = trigger('fadeOut', [fadeOutTransition]);

export const slideUpDown = trigger('slideUpDown', [
  transition(':enter', [style({ height: 0 }), animate('150ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ height: '*' }))]),
  transition(':leave', [style({ height: '*' }), animate('75ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ height: 0 }))]),
]);
