<div class="language-selection-container" [class.mobile]="appService.isMobile$ | async">
  <app-select-popover
    [disabled]="isLoading"
    [placeholder]="'PWA_Enter_dropdown_SelectLanguage' | translate"
    [selectedValue]="selectedLanguage"
    (valueChange)="languageChange($event)"
    [options]="languageOptions">
  </app-select-popover>
  <app-button
    data-cy="language-confirm-btn"
    id="select-language"
    [label]="'PWA_Enter_CTA_SelectLanguage_Confirm' | translate"
    [isLoading]="isLoading"
    [disabled]="!selectedLanguage || isLoading"
    [expand]="'block'"
    (clicked)="applyLanguage()" />
  @if (allowCancel) {
    <div class="cancel" data-cy="language-cancel-btn" (click)="cancel.emit()">{{ 'PWA_appLanguage_CTA_link_cancel' | translate }}</div>
  }
</div>
